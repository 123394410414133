import { elearningConstants } from '../constants';

const initialState = {};

export const elearning = (state = initialState, action) => {
  switch (action.type) {
    case elearningConstants.FETCH_DATA_REQUEST:
      return { 
        fetching: true
      };
    case elearningConstants.FETCH_DATA_SUCCESS:
      return action.data;
    case elearningConstants.FETCH_DATA_FAILURE:
      return {
        error: action.error
      };
    case elearningConstants.UPDATE_COURSE_PREP_DATA_REQUEST:
      return {
        ...state,
        updating: true
      };
    case elearningConstants.UPDATE_COURSE_PREP_DATA_SUCCESS:
      return action.data;
    case elearningConstants.UPDATE_COURSE_PREP_DATA_FAILURE:
      return state;
    case elearningConstants.COMPLETE_COURSE_REQUEST:
      return {
        completing: true
      }
    case elearningConstants.COMPLETE_COURSE_SUCCESS:
      return {}
    case elearningConstants.COMPLETE_COURSE_FAILURE:
      return {
        error: action.error
      }
    default:
      return state;
  }
};
