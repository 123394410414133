import React from 'react';

import Logo from '../Logo/Logo';

import { header } from './HeaderAccount.module.scss';

const HeaderAccount = () => {
  return (
    <div className={header}>
      <Logo />
    </div>
  )
}

export default HeaderAccount;
