import React from 'react';

import './WarningNotice.scss';

const WarningNotice = ({ boldText, normalText, closeCallback }) => {
  return (
    <div className="warning-notice">
      <span><strong>{ boldText }</strong> {normalText}</span>

      <button 
          type="button" 
          className="button button--icon"
          onClick={() => closeCallback()}
        >
      <svg className="icon-close">
        <use xlinkHref="#icon-close"></use>
      </svg>
      </button>
    </div>
  )
}

export default WarningNotice;
