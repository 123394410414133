import { combineReducers } from 'redux';

import { auth } from './auth.reducer';
import { user } from './user.reducer';
import { elearning } from './elearning.reducer';


const rootReducer = combineReducers({
  auth,
  user,
  elearning
});

export default rootReducer;
