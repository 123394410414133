import { authConstants } from '../constants';

let user = JSON.parse(localStorage.getItem('currentUser'));
const initialState = user ? { authenticated: true } : {};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return { 
        authenticating: true
      };
    case authConstants.LOGIN_SUCCESS:
      return { 
        authenticated: true,
      };
    case authConstants.LOGIN_FAILURE:
      const { message, membershipExpired } = action.error
      return {
        message,
        membershipExpired
      };
    case authConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};
