import React, { Component } from 'react';

import ServerErrorPage from '../../Pages/ServerError';

import { ServerError } from '../../errors';

class ErrorBoundary extends Component {
  state = {
    error: null,
  };

  componentDidCatch(error) {
    this.setState(() => ({
      error: error.name,
    }));
  }

  render() {
    if (this.state.error === ServerError.name) {
      return <ServerErrorPage history={this.props.history}/>
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
