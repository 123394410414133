export const userConstants = {
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  CHECK_REGISTRATION_REQUEST: 'CHECK_REGISTRATION_REQUEST',
  CHECK_REGISTRATION_SUCCESS: 'CHECK_REGISTRATION_SUCCESS',
  CHECK_REGISTRATION_FAILURE: 'CHECK_REGISTRATION_FAILURE',

  SEND_PASSWORD_RESET_REQUEST: 'SEND_PASSWORD_RESET_REQUEST',
  SEND_PASSWORD_RESET_SUCCESS: 'SEND_PASSWORD_RESET_SUCCESS',
  SEND_PASSWORD_RESET_FAILURE: 'SEND_PASSWORD_RESET_FAILURE',

  PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',

  SET_USER: 'SET_USER'
};
