import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';

import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const authenticated = useSelector(state => !!state.user.token);
  return (
    <Route {...rest} render={props => (
        authenticated
          ? <ErrorBoundary>
              <Component {...props} />
            </ErrorBoundary>
          : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )}
    />
  );
}
