export const elearningConstants = {
  FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST',
  FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS',
  FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE',

  UPDATE_COURSE_PREP_DATA_REQUEST: 'UPDATE_COURSE_PREP_DATA_REQUEST',
  UPDATE_COURSE_PREP_DATA_SUCCESS: 'UPDATE_COURSE_PREP_DATA_SUCCESS',
  UPDATE_COURSE_PREP_DATA_FAILURE: 'UPDATE_COURSE_PREP_DATA_FAILURE',

  COMPLETE_COURSE_REQUEST: 'COMPLETE_COURSE_REQUEST',
  COMPLETE_COURSE_SUCCESS: 'COMPLETE_COURSE_SUCCESS',
  COMPLETE_COURSE_FAILURE: 'COMPLETE_COURSE_FAILURE'
};
