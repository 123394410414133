import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { store } from './helpers';

import AppRouter from './routers/AppRouter';

import WarningNotice from './components/WarningNotice/WarningNotice';

import './App.scss';
import './Styles/Account/Account.scss';
import './Styles/Forms.scss';

function App() {
  const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
  const [SafariNoticeDismissed, setSafariNoticeDismissed] = useState(false);

  useEffect(() => {
    if (window.sessionStorage.getItem('SafariNoticeDismissed') !== null) {
      setSafariNoticeDismissed(true);
    }
  })

  return (
    <React.Fragment>
      { isSafari && !SafariNoticeDismissed &&
        <WarningNotice 
            boldText="Safari is not supported by this platform." 
            normalText="Please use Chrome, Edge or Firefox instead" 
            closeCallback={() => { 
              window.sessionStorage.setItem('SafariNoticeDismissed', 'true')
              setSafariNoticeDismissed(true)
            }} />
      }

      <Provider store={store}>
        <AppRouter />
      </Provider>
    </React.Fragment>
  );
}

export default App;
