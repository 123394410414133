import React from 'react';



const Logo = () => {
  return (
    <div className="logo">
      <svg width="101" height="47" viewBox="0 0 101 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.0546 22.975C27.0546 22.5828 27.1102 22.1905 27.1657 21.7983C27.1657 21.406 27.2213 21.0698 27.3324 20.6775C27.3324 20.6215 27.3324 20.5655 27.3879 20.5094C27.3879 20.5094 27.3879 20.5094 27.3879 20.4534C27.5546 19.6128 27.9435 19.0525 28.4434 18.7723C28.7212 18.6602 28.8879 18.6602 29.2767 18.6042C31.11 18.6042 31.5544 20.1732 31.6656 22.863H27.1657H27.0546V22.975ZM35.8321 25.6648V24.6561C35.8321 19.837 34.9432 16.4748 31.11 15.8584C30.2212 15.6902 29.5545 15.6902 29.2212 15.7463C23.6103 15.9144 22.777 20.2292 22.777 24.8243C22.777 29.1951 23.0547 34.2945 29.3879 34.2945C33.5544 34.2945 35.4432 31.997 35.6099 27.9063H31.6656C31.3878 30.0917 30.8322 31.2125 29.3323 31.2125C27.7212 31.2125 27.1657 29.1951 27.1657 27.1218V25.6648H35.8321Z" fill="white" />
        <path d="M89.608 22.975C89.608 22.5828 89.6635 22.1905 89.6635 21.7983C89.6635 21.406 89.7191 21.0698 89.8302 20.6775C89.8302 20.6215 89.8302 20.5655 89.8857 20.5094C89.8857 20.5094 89.8857 20.5094 89.8857 20.4534C90.0524 19.6128 90.4413 19.0525 90.9413 18.7723C91.219 18.6602 91.3857 18.6602 91.7746 18.6042C93.6078 18.6042 94.0523 20.1732 94.1634 22.863H89.6635H89.608V22.975ZM98.3299 25.6648V24.6561C98.3299 19.837 97.441 16.4748 93.6078 15.8584C92.719 15.6902 92.0523 15.6902 91.719 15.7463C86.1081 15.9144 85.2748 20.2292 85.2748 24.8243C85.2748 29.1951 85.5526 34.2945 91.8857 34.2945C96.0522 34.2945 97.941 31.997 98.1077 27.9063H94.1634C93.8856 30.0917 93.2745 31.2125 91.8301 31.2125C90.2191 31.2125 89.6635 29.1951 89.6635 27.1218V25.6648H98.3299Z" fill="white" />
        <path d="M69.7197 11.8798C69.7197 10.4789 70.8308 9.30212 72.2196 9.30212C73.6085 9.30212 74.7196 10.4229 74.7196 11.8798C74.7196 13.2807 73.6085 14.4575 72.2196 14.4575C70.8308 14.4575 69.7197 13.2807 69.7197 11.8798Z" fill="white" />
        <path d="M1.33337 10.3107H8.16647L11.4997 26.9536H11.5552L14.8329 10.3107H21.666V33.9022H17.4439V15.0738H17.3884L13.2774 33.9022H9.72197L5.611 15.0738H5.55545V33.9022H1.33337V10.3107Z" fill="white" />
        <path d="M46.2762 31.9969H46.2207C45.2763 33.622 44.0541 34.3505 42.2764 34.3505C38.6654 34.3505 36.9988 31.6047 36.9988 24.9923C36.9988 18.38 38.6654 15.6342 42.2764 15.6342C43.943 15.6342 45.0541 16.3066 45.9985 17.7636H46.054V10.3107H50.4983V33.9022H46.2762V31.9969ZM43.7208 30.8762C45.6651 30.8762 46.054 28.9149 46.054 25.0484C46.054 21.1818 45.6651 19.2205 43.7208 19.2205C41.7764 19.2205 41.3875 21.1818 41.3875 25.0484C41.4431 28.8589 41.8319 30.8762 43.7208 30.8762Z" fill="white" />
        <path d="M57.2757 10.3107H62.9977L69.053 33.9022H64.2199L63.1644 28.9149H57.0535L55.998 33.9022H51.2203L57.2757 10.3107ZM57.8868 24.9923H62.3866L60.1645 14.4014H60.1089L57.8868 24.9923Z" fill="white" />
        <path d="M74.3308 16.1385H69.8865V33.9022H74.3308V16.1385Z" fill="white" />
        <path d="M75.9418 16.1385H80.1638V18.492H80.2194C81.1083 16.7549 82.386 15.6342 84.2193 15.6342C84.497 15.6342 84.7748 15.6902 85.0526 15.6902V20.0611C84.6081 20.005 84.1081 20.005 83.6637 20.005C82.1082 20.005 80.3861 20.8456 80.3861 23.1991V33.8461H75.9418V16.1385Z" fill="white" />
        <path d="M71.9975 0.952625C76.1084 0.952625 79.6639 3.41825 81.386 6.94858L82.1082 6.38821C80.2194 2.63374 76.3862 0 71.9419 0C67.5532 0 63.7756 2.52166 61.8312 6.27613L62.5534 6.8365C64.3866 3.36221 67.9421 0.952625 71.9975 0.952625Z" fill="#EF820F" />
        <path d="M71.9975 5.3795C74.664 5.3795 76.9973 7.17268 77.8306 9.6383L78.6083 9.07793C77.5528 6.38816 74.9973 4.48291 71.9975 4.48291C69.0531 4.48291 66.4977 6.33213 65.4421 8.96586L66.2199 9.52623C67.1087 7.11664 69.3864 5.43554 71.9975 5.3795Z" fill="#EF820F" />
        <path d="M3.33475 39.6038H3.35286L4.17689 42.755H2.43828L3.33475 39.6038ZM0.96228 45.2029H1.79536L2.27529 43.4126H4.33988L4.8017 45.2029H5.63478L3.89618 38.6812H2.82766L0.96228 45.2029Z" fill="white" />
        <path d="M5.96077 45.2029H6.72141V41.8233C6.72141 41.12 7.27378 40.8368 7.68127 40.8368C8.36946 40.8368 8.39663 41.4488 8.39663 41.8233V45.2029H9.15727V41.732C9.15727 41.1474 9.15727 40.1792 7.88048 40.1792C7.40961 40.1792 6.91157 40.435 6.7033 40.8825H6.68519V40.3071H5.96077V45.2029Z" fill="white" />
        <path d="M12.1557 45.2029H12.9525V38.6812H12.1557V45.2029Z" fill="white" />
        <path d="M13.8771 45.2029H14.6377V41.8233C14.6377 41.12 15.1901 40.8368 15.5976 40.8368C16.2858 40.8368 16.313 41.4488 16.313 41.8233V45.2029H17.0736V41.732C17.0736 41.1474 17.0736 40.1792 15.7968 40.1792C15.3259 40.1792 14.8279 40.435 14.6196 40.8825H14.6015V40.3071H13.8771V45.2029Z" fill="white" />
        <path d="M18.1955 40.3071H17.4891V40.9099H18.1955V44.2895C18.1955 44.9289 18.3766 45.2577 19.1734 45.2577C19.4904 45.2577 19.6896 45.2303 19.7892 45.2029V44.5727C19.7077 44.5818 19.6262 44.6001 19.4541 44.6001C19.0557 44.6001 18.9561 44.4448 18.9561 44.052V40.9099H19.7982V40.3071H18.9561V38.8822H18.1955V40.3071Z" fill="white" />
        <path d="M23.4003 42.8463V42.5358C23.4003 41.4762 23.2645 40.1792 21.7794 40.1792C20.2853 40.1792 20.0499 41.6041 20.0499 42.8189C20.0499 44.5087 20.5389 45.3308 21.7341 45.3308C23.0653 45.3308 23.3641 44.116 23.3641 43.5953H22.6034C22.6034 43.9698 22.5038 44.7279 21.7794 44.7279C20.8467 44.7279 20.8467 43.6045 20.8467 42.8463H23.4003ZM20.8739 42.2435V42.0608C20.8739 41.7685 20.9645 40.782 21.7613 40.782C22.4405 40.782 22.6034 41.4306 22.6034 41.9786V42.2435H20.8739Z" fill="white" />
        <path d="M24.1368 45.2029H24.8975V42.1065C24.8975 41.3027 25.5132 40.9465 25.8935 40.9465C26.138 40.9465 26.2286 40.9739 26.301 40.983V40.234C26.2286 40.1975 26.1199 40.1792 25.966 40.1792C25.4136 40.1792 25.1329 40.572 24.9156 41.0378H24.8975V40.3071H24.1368V45.2029Z" fill="white" />
        <path d="M26.7167 45.2029H27.4773V41.8233C27.4773 41.12 28.0297 40.8368 28.4371 40.8368C29.1253 40.8368 29.1525 41.4488 29.1525 41.8233V45.2029H29.9131V41.732C29.9131 41.1474 29.9131 40.1792 28.6364 40.1792C28.1655 40.1792 27.6674 40.435 27.4592 40.8825H27.4411V40.3071H26.7167V45.2029Z" fill="white" />
        <path d="M33.0362 43.7963C33.0362 44.3078 32.5653 44.7279 32.0582 44.7279C31.5421 44.7279 31.3701 44.39 31.3701 43.8876C31.3701 43.4583 31.4968 43.2665 31.7413 43.1112C32.0854 42.8829 32.7374 42.8372 33.0362 42.5358V43.7963ZM31.4515 41.7868V41.7411C31.4515 41.5584 31.4515 40.782 32.2846 40.782C32.7917 40.782 33.0634 41.0378 33.0634 41.5036C33.0634 41.9055 33.0724 42.0699 32.511 42.2435L31.4878 42.554C30.863 42.7459 30.5732 43.2117 30.5732 43.9059C30.5732 44.7827 30.9897 45.3308 31.8953 45.3308C32.3571 45.3308 32.8189 45.0659 33.0634 44.6275H33.0815C33.1087 45.2577 33.489 45.2577 33.7697 45.2577C33.9598 45.2577 34.15 45.2212 34.2134 45.212V44.664C34.1772 44.6914 34.1319 44.7097 34.0323 44.7097C33.7878 44.7097 33.7969 44.527 33.7969 44.3169V41.5858C33.7969 41.0743 33.7969 40.1792 32.3208 40.1792C31.1618 40.1792 30.6909 40.6816 30.6909 41.7868H31.4515Z" fill="white" />
        <path d="M35.0474 40.3071H34.3411V40.9099H35.0474V44.2895C35.0474 44.9289 35.2285 45.2577 36.0254 45.2577C36.3423 45.2577 36.5415 45.2303 36.6411 45.2029V44.5727C36.5596 44.5818 36.4781 44.6001 36.3061 44.6001C35.9076 44.6001 35.808 44.4448 35.808 44.052V40.9099H36.6502V40.3071H35.808V38.8822H35.0474V40.3071Z" fill="white" />
        <path d="M37.1101 39.5581H37.8707V38.6812H37.1101V39.5581ZM37.1101 45.2029H37.8707V40.3071H37.1101V45.2029Z" fill="white" />
        <path d="M38.5779 42.755C38.5779 44.1342 38.922 45.3308 40.3256 45.3308C41.7201 45.3308 42.0733 44.1342 42.0733 42.755C42.0733 41.3758 41.7292 40.1792 40.3256 40.1792C38.922 40.1792 38.5779 41.3758 38.5779 42.755ZM40.3075 44.7279C39.5287 44.7279 39.3748 43.8145 39.3748 42.755C39.3748 41.6954 39.5378 40.782 40.3075 40.782C41.1315 40.782 41.2764 41.6954 41.2764 42.755C41.2764 43.8145 41.1134 44.7279 40.3075 44.7279Z" fill="white" />
        <path d="M42.7373 45.2029H43.498V41.8233C43.498 41.12 44.0504 40.8368 44.4578 40.8368C45.146 40.8368 45.1732 41.4488 45.1732 41.8233V45.2029H45.9338V41.732C45.9338 41.1474 45.9338 40.1792 44.6571 40.1792C44.1862 40.1792 43.6881 40.435 43.4799 40.8825H43.4618V40.3071H42.7373V45.2029Z" fill="white" />
        <path d="M49.0569 43.7963C49.0569 44.3078 48.586 44.7279 48.0789 44.7279C47.5628 44.7279 47.3907 44.39 47.3907 43.8876C47.3907 43.4583 47.5175 43.2665 47.762 43.1112C48.1061 42.8829 48.7581 42.8372 49.0569 42.5358V43.7963ZM47.4722 41.7868V41.7411C47.4722 41.5584 47.4722 40.782 48.3053 40.782C48.8124 40.782 49.0841 41.0378 49.0841 41.5036C49.0841 41.9055 49.0931 42.0699 48.5317 42.2435L47.5085 42.554C46.8837 42.7459 46.5939 43.2117 46.5939 43.9059C46.5939 44.7827 47.0104 45.3308 47.916 45.3308C48.3778 45.3308 48.8396 45.0659 49.0841 44.6275H49.1022C49.1294 45.2577 49.5097 45.2577 49.7904 45.2577C49.9805 45.2577 50.1707 45.2212 50.2341 45.212V44.664C50.1979 44.6914 50.1526 44.7097 50.053 44.7097C49.8085 44.7097 49.8175 44.527 49.8175 44.3169V41.5858C49.8175 41.0743 49.8176 40.1792 48.3415 40.1792C47.1825 40.1792 46.7116 40.6816 46.7116 41.7868H47.4722Z" fill="white" />
        <path d="M50.7986 45.2029H51.5592V38.6812H50.7986V45.2029Z" fill="white" />
        <path d="M58.1454 40.371C58.1182 39.1653 57.5749 38.5533 56.3343 38.5533C54.6954 38.5533 54.4418 39.7499 54.4418 40.3527C54.4418 42.7459 57.4934 41.6772 57.4934 43.5222C57.4934 44.2164 57.0044 44.6731 56.3706 44.6731C55.2115 44.6731 55.1572 43.8876 55.1572 43.1569H54.3603C54.3603 44.5453 54.804 45.3308 56.3162 45.3308C57.2308 45.3308 58.3265 44.9015 58.3265 43.3944C58.3265 41.0104 55.2749 42.1065 55.2749 40.2705C55.2749 39.5764 55.6643 39.211 56.3434 39.211C57.104 39.211 57.3485 39.686 57.3485 40.371H58.1454Z" fill="white" />
        <path d="M61.025 39.211C62.1297 39.211 62.2746 40.435 62.2746 41.9421C62.2746 43.4492 62.1297 44.6731 61.025 44.6731C59.9202 44.6731 59.7753 43.4492 59.7753 41.9421C59.7753 40.435 59.9202 39.211 61.025 39.211ZM61.025 38.5533C59.2411 38.5533 58.9423 40.2979 58.9423 41.9421C58.9423 43.5862 59.2411 45.3308 61.025 45.3308C62.8088 45.3308 63.1077 43.5862 63.1077 41.9421C63.1077 40.2979 62.8088 38.5533 61.025 38.5533Z" fill="white" />
        <path d="M67.5055 40.371C67.4783 39.1653 66.935 38.5533 65.6944 38.5533C64.0554 38.5533 63.8019 39.7499 63.8019 40.3527C63.8019 42.7459 66.8535 41.6772 66.8535 43.5222C66.8535 44.2164 66.3645 44.6731 65.7306 44.6731C64.5716 44.6731 64.5172 43.8876 64.5172 43.1569H63.7204C63.7204 44.5453 64.1641 45.3308 65.6763 45.3308C66.5909 45.3308 67.6866 44.9015 67.6866 43.3944C67.6866 41.0104 64.635 42.1065 64.635 40.2705C64.635 39.5764 65.0243 39.211 65.7035 39.211C66.4641 39.211 66.7086 39.686 66.7086 40.371H67.5055Z" fill="white" />
        <path d="M73.5927 42.9651C73.5655 43.7597 73.4297 44.6731 72.4608 44.6731C71.3561 44.6731 71.2112 43.4492 71.2112 41.9421C71.2112 40.435 71.3561 39.211 72.4608 39.211C73.2848 39.211 73.5112 39.8412 73.5384 40.5811H74.3715C74.3352 39.3023 73.7738 38.5533 72.4608 38.5533C70.6769 38.5533 70.3781 40.2979 70.3781 41.9421C70.3781 43.5862 70.6226 45.3308 72.4608 45.3308C73.8734 45.3308 74.3533 44.1982 74.4258 42.9651H73.5927Z" fill="white" />
        <path d="M74.8021 42.755C74.8021 44.1342 75.1462 45.3308 76.5497 45.3308C77.9442 45.3308 78.2974 44.1342 78.2974 42.755C78.2974 41.3758 77.9533 40.1792 76.5497 40.1792C75.1462 40.1792 74.8021 41.3758 74.8021 42.755ZM76.5316 44.7279C75.7529 44.7279 75.5989 43.8145 75.5989 42.755C75.5989 41.6954 75.7619 40.782 76.5316 40.782C77.3557 40.782 77.5005 41.6954 77.5005 42.755C77.5005 43.8145 77.3375 44.7279 76.5316 44.7279Z" fill="white" />
        <path d="M79.0339 45.2029H79.7946V41.8233C79.7946 41.0561 80.3379 40.8368 80.7001 40.8368C81.3249 40.8368 81.3521 41.4488 81.3521 41.8233V45.2029H82.1127V41.8233C82.1127 41.0561 82.656 40.8368 83.0182 40.8368C83.643 40.8368 83.6702 41.4488 83.6702 41.8233V45.2029H84.4308V41.732C84.4308 41.1474 84.4308 40.1792 83.1541 40.1792C82.647 40.1792 82.2214 40.5263 82.004 40.9556C81.8501 40.3984 81.5241 40.1792 80.9536 40.1792C80.4828 40.1792 79.9847 40.435 79.7764 40.8825H79.7583V40.3071H79.0339V45.2029Z" fill="white" />
        <path d="M85.3969 46.8105H86.1575V44.6457H86.1756C86.3476 45.075 86.7551 45.3308 87.1898 45.3308C88.4485 45.3308 88.7745 43.778 88.7745 42.6636C88.7745 41.2022 88.3398 40.1792 87.1898 40.1792C86.6646 40.1792 86.239 40.5446 86.1394 40.9099H86.1213V40.3071H85.3969V46.8105ZM86.1122 42.755C86.1122 41.6315 86.2571 40.8368 87.0902 40.8368C87.7874 40.8368 87.9776 41.5858 87.9776 42.6636C87.9776 43.9333 87.7874 44.6731 87.0902 44.6731C86.2662 44.6731 86.1122 43.7049 86.1122 42.755Z" fill="white" />
        <path d="M91.7164 43.7963C91.7164 44.3078 91.2455 44.7279 90.7384 44.7279C90.2223 44.7279 90.0503 44.39 90.0503 43.8876C90.0503 43.4583 90.177 43.2665 90.4215 43.1112C90.7656 42.8829 91.4176 42.8372 91.7164 42.5358V43.7963ZM90.1317 41.7868V41.7411C90.1317 41.5584 90.1318 40.782 90.9648 40.782C91.4719 40.782 91.7436 41.0378 91.7436 41.5036C91.7436 41.9055 91.7526 42.0699 91.1912 42.2435L90.168 42.554C89.5432 42.7459 89.2534 43.2117 89.2534 43.9059C89.2534 44.7827 89.6699 45.3308 90.5754 45.3308C91.0373 45.3308 91.4991 45.0659 91.7436 44.6275H91.7617C91.7888 45.2577 92.1692 45.2577 92.4499 45.2577C92.64 45.2577 92.8302 45.2212 92.8936 45.212V44.664C92.8574 44.6914 92.8121 44.7097 92.7125 44.7097C92.468 44.7097 92.4771 44.527 92.4771 44.3169V41.5858C92.4771 41.0743 92.4771 40.1792 91.0011 40.1792C89.842 40.1792 89.3711 40.6816 89.3711 41.7868H90.1317Z" fill="white" />
        <path d="M93.4128 45.2029H94.1734V41.8233C94.1734 41.12 94.7258 40.8368 95.1333 40.8368C95.8215 40.8368 95.8486 41.4488 95.8486 41.8233V45.2029H96.6093V41.732C96.6093 41.1474 96.6093 40.1792 95.3325 40.1792C94.8616 40.1792 94.3636 40.435 94.1553 40.8825H94.1372V40.3071H93.4128V45.2029Z" fill="white" />
        <path d="M98.6164 45.3034L98.4715 45.7327C98.3719 45.9976 98.2271 46.2076 97.8739 46.2076C97.6837 46.2076 97.5389 46.1985 97.4302 46.1802V46.7831C97.566 46.7922 97.6837 46.8105 97.9826 46.8105C98.8971 46.8105 99.0873 46.2076 99.2775 45.5317L100.681 40.3071H99.8479L98.9968 44.2347H98.9786L98.0188 40.3071H97.1857L98.6164 45.3034Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M99.4729 32.955H99.2241V33.6493H98.9596V32.0254H99.5702C99.959 32.0254 100.157 32.1627 100.157 32.4922C100.157 32.7863 99.9745 32.9079 99.7373 32.9354L100.188 33.6493H99.889L99.4729 32.955ZM99.2241 32.2529V32.7275H99.5196C99.7179 32.7275 99.8928 32.7079 99.8928 32.4726C99.8928 32.2725 99.6985 32.2529 99.5429 32.2529H99.2241Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M101 32.8673C101 33.6883 100.34 34.3539 99.5262 34.3539C98.7123 34.3539 98.0525 33.6883 98.0525 32.8673C98.0525 32.0463 98.7123 31.3807 99.5262 31.3807C100.34 31.3807 101 32.0463 101 32.8673ZM100.695 32.8673C100.695 33.5181 100.171 34.0458 99.5262 34.0458C98.881 34.0458 98.3581 33.5181 98.3581 32.8673C98.3581 32.2165 98.881 31.689 99.5262 31.689C100.171 31.689 100.695 32.2165 100.695 32.8673Z" fill="white" />
      </svg>
    </div>
  )
}

export default Logo;
