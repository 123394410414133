import React from 'react';
import Fade from 'react-reveal/Fade'
import HeaderAccount from '../components/HeaderAccount/HeaderAccount';
import { Link } from 'react-router-dom';


const ServerErrorPage = () => {
  return (

    <Fade duration={1000} delay={300}>
      <div className="l_account account--forgot-password">
        <HeaderAccount />
        <div className="l_account-left">
          <div className="compass">
          </div>
          <div className="col-height"></div>
        </div>
        <div className="l_account-right">
          <div className="compass">
          </div>
          <form className="form" action="">
            <div className="form__inner">
              <Fade delay={400} duration={600} distance={'30px'} bottom >
                <div>
                  <div className="account__header account__header--forgot-password">

                    <div>
                      <h1 className="account__title h1">
                        500
                      </h1>
                      <p>
                        Oops! Something went wrong.
                      </p>
                    </div>

                  </div>
                </div>
              </Fade>
            </div>
          </form>
          <div className="col-height"></div>
        </div>
      </div >
    </Fade>

  );
}

export default ServerErrorPage;
