import React, { Suspense, lazy } from 'react';
import {
  Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import { history } from '../helpers';

import { PrivateRoute } from './PrivateRoute';

const StartPage = lazy(() => import('../Pages/Start'));
const LoginPage = lazy(() => import('../Pages/Login'));
const RegisterPage = lazy(() => import('../Pages/Register'));
const ForgotPasswordPage = lazy(() => import('../Pages/ForgotPassword'));
const ExpiredMembershipPage = lazy(() => import('../Pages/ExpiredMembership'));
const AgendaPage = lazy(() => import('../Pages/Agenda'));
const TrainingVideoPage = lazy(() => import('../Pages/TrainingVideo'));
const DashboardPage = lazy(() => import('../Pages/Dashboard'));
const TrainingPage = lazy(() => import('../Pages/Training'));
const TrainingTutorialPage = lazy(() => import('../Pages/TrainingTutorial'));
const ContinuousLearningPage = lazy(() => import('../Pages/ContinousLearning'));
const CallEndPage = lazy(() => import('../Pages/CallEnd'));
const NotFoundPage = lazy(() => import('../Pages/NotFound'));
const ServerErrorPage = lazy(() => import('../Pages/ServerError'));

const AppRouter = () => (
  <Router history={history}>
    <Suspense fallback={<div />}>
      <Switch>
        <PrivateRoute exact path="/" component={DashboardPage} />
        <Route path="/start" component={StartPage} />
        <PrivateRoute path="/training" component={TrainingPage} />
        <Route path="/call-end" component={CallEndPage} />
        <Route path="/login" component={LoginPage} />
        <Route path="/register" component={RegisterPage} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/resetpassword" component={ForgotPasswordPage} />
        <Route path="/resetmessage" component={ForgotPasswordPage} />
        <Route path="/expired-membership" component={ExpiredMembershipPage} />
        <PrivateRoute path="/agenda" component={AgendaPage} />
        <PrivateRoute path="/training-video" component={TrainingVideoPage} />
        <PrivateRoute path="/training-tutorial" component={TrainingTutorialPage} />
        <PrivateRoute path="/continuous-learning" component={ContinuousLearningPage} />
        <Route path="/error" component={ServerErrorPage} />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  </Router>
);

export default AppRouter;
