import { userConstants } from '../constants';

let currentUser = JSON.parse(localStorage.getItem('currentUser'));
const initialState = currentUser || {};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.REGISTER_REQUEST:
      return { 
        registering: true
      };
    case userConstants.REGISTER_SUCCESS:
      return action.user;
    case userConstants.REGISTER_FAILURE: {
      const { message } = action.error
      return {
        message
      };
    }
    case userConstants.CHECK_REGISTRATION_REQUEST:
      return { 
        checkingRegistration: true
      };
    case userConstants.CHECK_REGISTRATION_SUCCESS:
      return { 
        registered: action.registered
      };
    case userConstants.CHECK_REGISTRATION_FAILURE:
      return {};
    case userConstants.SEND_PASSWORD_RESET_REQUEST:
      return {
        requestingReset: true
      };
    case userConstants.SEND_PASSWORD_RESET_SUCCESS:
      return {
        resetSent: true
      }
    case userConstants.SEND_PASSWORD_RESET_FAILURE:
      return {}
    case userConstants.PASSWORD_RESET_REQUEST:
      return {
        resetting: true
      };
    case userConstants.PASSWORD_RESET_SUCCESS:
      return {
        reset: true
      }
    case userConstants.PASSWORD_RESET_FAILURE: {
      const { message } = action.error
      return {
        message
      };
    }
    case userConstants.SET_USER:
      return action.user
    default:
      return state;
  }
};
